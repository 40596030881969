import { createSlice } from "@reduxjs/toolkit";
import { SignUp_user, Signin_user, async_loaduser, async_removeuser} from "../Actions/authActions";

let initialState = {
  user: [],
  userType:null,
  isAuthenticated: false,
  loading: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(SignUp_user.pending, (state) => {
        state.loading = true;
      })
      .addCase(SignUp_user.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userType = action.payload.UserType
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(SignUp_user.rejected, (state) => {
        state.loading = false;
      })
      .addCase(Signin_user.pending, (state) => {
        state.loading = true;
      })
      .addCase(Signin_user.fulfilled, (state, action) => {
        state.user = action.payload;
        state.userType = action.payload.UserType
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(Signin_user.rejected, (state) => {
        state.loading = false;
      })      
      .addCase(async_loaduser.pending, (state) => {
        state.loading = true;
      })
      .addCase(async_loaduser.fulfilled, (state, action) => {
        // console.log(action.payload)
        state.userType = action.payload.UserType
        state.user = action.payload
        state.isAuthenticated = true;
        state.loading = false;
      })
      .addCase(async_loaduser.rejected, (state) => {
        state.loading = false;
      })
      .addCase(async_removeuser.pending, (state) => {
        state.loading = true;
      })
      .addCase(async_removeuser.fulfilled, (state, action) => {
        state.isAuthenticated = false;
        state.userType = null;
        state.loading = false;
      })
      .addCase(async_removeuser.rejected, (state) => {
        state.loading = false;
      })
  },
});

export default authSlice.reducer;
