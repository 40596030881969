import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../../helpers/axiosconfig";

// ACTIONS : api calls

// --------------------------------------------------------------------------------- auth  --------

export const SignUp_user = createAsyncThunk(
  "Admin/Signup",
  async (formData) => {
    const response = await axios.post(`Signup`, formData);
    console.log(response);
    return response.data.data;
  }
);

export const async_loaduser = createAsyncThunk(
  "student/async_loaduser",
  async () => {
    const response = await axios.post(`me`);
    console.log(response);
    return response.data.user;
  }
);

export const async_removeuser = createAsyncThunk(
  "student/async_removeuser",
  async () => {
    const response = await axios.get(`signout`);
    // console.log(response)
    return response;
  }
);

export const Signin_user = createAsyncThunk(
  "Admin/Signin_user",
  async (formData) => {
    const response = await axios.post(`Signin_user`, formData);
    console.log(response);
    return response.data.data;
  }
);

// -----
